.container {
  position: absolute;
  /*top: -4rem;*/ /* TODO: drive from constant */
  left: 0;
  width: 100vw;
  height: 100vh;
  /*background-color: rgba(112, 128, 144, 0.8);*/ /* TODO: make theme driven */
  backdrop-filter: blur(5px);
  z-index: 999;
}

.spinnerOutter {
  text-align: center;
}
